import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout/Layout";

// Eventhough not used directly (as a Gatsby module), this will overwrite any global styles
import aboutStyles from "./styles/about-page.scss";

// Images
import ngv_homepage_laptop from "../img/layout/ngv_homepage_laptop.webp";
import hiker_man_colour from "../img/layout/hiker-man-colour.svg";

// Other components and their stlyes
import Navigation from "../components/Layout/Navigation";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export class AboutPageTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageIndex: 0,
      isLightboxOpened: false
    };
  }

  showImage = index => {
    this.setState({
      isLightboxOpened: true,
      imageIndex: index
    });
  };

  getImage = index => {
    return this.props.lightbox.allImages[index];
  };

  getImageSrc = index => {
    return this.props.lightbox.allImages[index].image.childImageSharp.fluid.src;
  };

  getImagesLength = () => {
    return this.props.lightbox.allImages.length;
  };

  render() {
    const { imageIndex, isLightboxOpened } = this.state;

    return (
      <div className="about-page">
        <div className="intro">
          <Navigation />

          <div className="container">
            <div className="column mt-5 about-us-row">
              <div className="col about-us-title">
                <h1>{this.props.title}</h1>
              </div>
              <div className="col about-us-subtitle">
                <p>{this.props.subtitle}</p>
              </div>
            </div>

            <div className="card-deck">
              {this.props.lightbox.promoImages.map((item, index) => (
                <div
                  key={item.alt}
                  className="card"
                  style={{ width: "100%" }}
                  onClick={() => this.showImage(index)}
                >
                  <Img
                    fluid={item.image.childImageSharp.fluid}
                    title={item.alt}
                  />
                </div>
              ))}
              {isLightboxOpened && (
                <Lightbox
                  mainSrc={this.getImageSrc(imageIndex)}
                  nextSrc={this.getImageSrc(
                    (imageIndex + 1) % this.getImagesLength()
                  )}
                  prevSrc={this.getImageSrc(
                    (imageIndex + this.getImagesLength() - 1) %
                      this.getImagesLength()
                  )}
                  onCloseRequest={() =>
                    this.setState({ isLightboxOpened: false })
                  }
                  onMovePrevRequest={() =>
                    this.setState({
                      imageIndex:
                        (imageIndex + this.getImagesLength() - 1) %
                        this.getImagesLength()
                    })
                  }
                  onMoveNextRequest={() =>
                    this.setState({
                      imageIndex: (imageIndex + 1) % this.getImagesLength()
                    })
                  }
                  imageTitle={this.getImage(imageIndex).alt}
                  enableZoom={false}
                />
              )}
            </div>
          </div>
        </div>

        {/* <section className="container-fluid">
          <div className="container neogov-container">
            <div className="row flex-md-row flex-sm-column">
              <div className="col-md-6 content-wrapper">
                <h1>
                  <span className="neo">NEO</span>
                  <span className="gov">GOV</span>
                  <span className="ampersand">&nbsp;&amp;&nbsp;</span>
                  <span className="hrprodigy">HR Prodigy</span>
                </h1>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: this.props.neogov.description
                  }}
                />
              </div>
              <div className="col image-wrapper">
                <img src={ngv_homepage_laptop} />
              </div>
            </div>
          </div>
        </section> */}

        <section className="container-fluid company-culture-container">
          <div className="container">
            <div className="row flex-md-row flex-sm-column">
              <div className="col-md-6 image-wrapper">
                <img
                  className="d-block mx-auto mx-md-0"
                  src={hiker_man_colour}
                />
              </div>
              <div className="col content-wrapper">
                <h1>Office culture</h1>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: this.props.companyCulture.description
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  neogov: PropTypes.object,
  companyCulture: PropTypes.object,
  lightbox: PropTypes.object
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout pageTitle={post.frontmatter.title}>
      <AboutPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        neogov={post.frontmatter.neogov}
        companyCulture={post.frontmatter.companyCulture}
        lightbox={post.frontmatter.lightbox}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      frontmatter {
        title
        subtitle
        neogov {
          description
        }
        companyCulture {
          description
        }
        lightbox {
          promoImages {
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 96) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          allImages {
            image {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080, quality: 96) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`;
